import { default as indexUeISx0SctbMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/[category]/index.vue?macro=true";
import { default as indexDKhE5FYsZVMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/index.ts?macro=true";
import { default as PageFiltersYI01IoFeMvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/PageFilters.vue?macro=true";
import { default as SectionCategoriesLGvE7tXW7rMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/SectionCategories.vue?macro=true";
import { default as SectionCollectionList11Yp8D75TaMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/SectionCollectionList.vue?macro=true";
import { default as SectionFiltersFkZNV71JNqMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/SectionFilters.vue?macro=true";
import { default as SectionGenresQk08QWMiGaMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/SectionGenres.vue?macro=true";
import { default as SectionMoviesjUrBNKjpl6Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/SectionMovies.vue?macro=true";
import { default as SectionPopularYdJWXtnmZ8Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/SectionPopular.vue?macro=true";
import { default as SectionSeriesMAsi5gTrFHMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/SectionSeries.vue?macro=true";
import { default as SkeletonFiltersnso4jRonoOMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/SkeletonFilters.vue?macro=true";
import { default as SkeletonGenres7xPLHh4fWGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/SkeletonGenres.vue?macro=true";
import { default as _91slug_93TRtOyFh0ppMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/genre/[slug].vue?macro=true";
import { default as indexiXq7f6OYayMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/index.vue?macro=true";
import { default as _91slug_93AnQv5kvFsqMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/collections/[slug].vue?macro=true";
import { default as indexGF2Yp2y5veMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/collections/index.vue?macro=true";
import { default as copyrightmVxRr9KlI7Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/copyright.vue?macro=true";
import { default as _91id_93B03LdnVn3vMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/crew/[id].vue?macro=true";
import { default as indexR6AKM2UXzaMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/faq/index.vue?macro=true";
import { default as allWWb78bQaHYMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/favorites/all.vue?macro=true";
import { default as SectionBoughtSQbaD0oAKgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/favorites/components/SectionBought.vue?macro=true";
import { default as SectionContinueoejPfcs2YiMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/favorites/components/SectionContinue.vue?macro=true";
import { default as SectionFavoritesuxRFRkRHRAMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/favorites/components/SectionFavorites.vue?macro=true";
import { default as SectionViewediwBN1K1uYkMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/favorites/components/SectionViewed.vue?macro=true";
import { default as continuegEg0mrGpYQMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/favorites/continue.vue?macro=true";
import { default as indexhUGdaJSkynMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/favorites/index.vue?macro=true";
import { default as purchasedIkRP0U8OO3Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/favorites/purchased.vue?macro=true";
import { default as viewedkKZlBlsRCIMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/favorites/viewed.vue?macro=true";
import { default as holder_45policyC270byogMJMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/holder-policy.vue?macro=true";
import { default as SectionAdvantagesrQY8bL1tMDMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionAdvantages.vue?macro=true";
import { default as SectionBannerHLV4QbXjxKMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionBanner.vue?macro=true";
import { default as SectionCollectionsjUssAcZOAnMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionCollections.vue?macro=true";
import { default as SectionContinueyuWGYWsNUZMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionContinue.vue?macro=true";
import { default as SectionFilmsx8XxIuaHDAMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionFilms.vue?macro=true";
import { default as SectionGenressgqT02Rrb9Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionGenres.vue?macro=true";
import { default as SectionHeaderkhli0r4v8wMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionHeader.vue?macro=true";
import { default as SectionPopularVgWtHvDNSlMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionPopular.vue?macro=true";
import { default as SectionPremierswkNsOkHTxxMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionPremiers.vue?macro=true";
import { default as SectionSeries4uWZ2VoBjaMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionSeries.vue?macro=true";
import { default as SectionSubscriptions3balf9i1TWMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionSubscriptions.vue?macro=true";
import { default as SectionSummarizehb1QI6dpqzMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionSummarize.vue?macro=true";
import { default as SectionTopMoviesWXs4oXqUSeMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionTopMovies.vue?macro=true";
import { default as SectionUpcomingQOWfyj9GItMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionUpcoming.vue?macro=true";
import { default as IndexgaoxMjkXaoMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/Index.vue?macro=true";
import { default as _91slug_93a9DLqmtbXjMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/install/[slug].vue?macro=true";
import { default as InfoInstallVS7ul8I8rWMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/install/components/InfoInstall.vue?macro=true";
import { default as TitleInstall8bNc6jGd1FMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/install/components/TitleInstall.vue?macro=true";
import { default as typesfWNSRvaxRgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/install/components/types.ts?macro=true";
import { default as useInstallmyI7whVYmSMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/install/components/useInstall.ts?macro=true";
import { default as filmcrew6SsPiV5D0rMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/[slug]/filmcrew.vue?macro=true";
import { default as indexgt8zqHcNyCMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/[slug]/index.vue?macro=true";
import { default as MovieCollectionsYv3ZAQY6XWMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieCollections.vue?macro=true";
import { default as MovieCrewBXtlXkJ8NfMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieCrew.vue?macro=true";
import { default as MovieDescriptionvK3kl5y7lkMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieDescription.vue?macro=true";
import { default as MovieHeaderRiW3qTr8vxMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieHeader.vue?macro=true";
import { default as MovieHeaderNoticeLMe5TkO6dpMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieHeaderNotice.vue?macro=true";
import { default as MovieHeaderSkeletonhQDcugJKzjMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieHeaderSkeleton.vue?macro=true";
import { default as MoviePlayButtonKGPX3lgLZgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MoviePlayButton.vue?macro=true";
import { default as MovieRelatedTJvn758xXJMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieRelated.vue?macro=true";
import { default as MovieSeriesHtRcfcOi5YMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieSeries.vue?macro=true";
import { default as MovieTrailersPNkUeUfAVSMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieTrailers.vue?macro=true";
import { default as MovieWatchOptionsJ82HbMGFfcMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieWatchOptions.vue?macro=true";
import { default as _91slug_93sp6XWKWjgmMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/news/[slug].vue?macro=true";
import { default as indexYUNHg5fR8LMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/news/index.vue?macro=true";
import { default as novelty_45filmsMZ6w4ui9vgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/novelty-films.vue?macro=true";
import { default as novelty_45seriesdx37FeU7P6Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/novelty-series.vue?macro=true";
import { default as policy_45agreementCQmzr9REwvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/policy-agreement.vue?macro=true";
import { default as policy_45copyrightwGrHyYEFvmMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/policy-copyright.vue?macro=true";
import { default as policy_45holderbiDy1M5viJMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/policy-holder.vue?macro=true";
import { default as policy_45privateg3gvK4fT7MMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/policy-private.vue?macro=true";
import { default as policy_45userDO1O4T93RDMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/policy-user.vue?macro=true";
import { default as premiersvroXfDltcPMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/premiers.vue?macro=true";
import { default as private_45policyBNvVJDBT5ZMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/private-policy.vue?macro=true";
import { default as indexJNUNaPlbq6Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/bonuses/index.vue?macro=true";
import { default as VChangeRecurrentCardModal0LsLEFT1y3Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue?macro=true";
import { default as VEditProfileit8uXFy66YMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/modal/VEditProfile.vue?macro=true";
import { default as VRejectSubscribeModal0N9UvnrEdiMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/modal/VRejectSubscribeModal.vue?macro=true";
import { default as VRemoveUserDataxi7oddQ1ozMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/modal/VRemoveUserData.vue?macro=true";
import { default as VResumeSubscriptionSuccessModalhRza6zIq6PMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue?macro=true";
import { default as PersonalReferrernUOisXsCCWMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/PersonalReferrer.vue?macro=true";
import { default as validationQHueojuKP0Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/validation.ts?macro=true";
import { default as VBonusHistory9gXcfQOH3BMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/VBonusHistory.vue?macro=true";
import { default as VNotificationCardMDjvIzuM8DMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/VNotificationCard.vue?macro=true";
import { default as VNotificationsSettingsdYpICzodMQMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/VNotificationsSettings.vue?macro=true";
import { default as VPaymentshVmPgUvvisMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/VPayments.vue?macro=true";
import { default as VProfileTabsbp2rXWFNWjMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/VProfileTabs.vue?macro=true";
import { default as VReferralProgramUsYP1a81UTMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/VReferralProgram.vue?macro=true";
import { default as VSubscriptionsQui5KCGIW9Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/VSubscriptions.vue?macro=true";
import { default as indexJEzRpVG5LuMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/devices/index.vue?macro=true";
import { default as indexCbCktHoHf2Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/index.vue?macro=true";
import { default as indexi5c8cm7u4VMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/notifications/index.vue?macro=true";
import { default as indexuS6U9FA6b3Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/payments/index.vue?macro=true";
import { default as indextuF79g0eLlMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/settings/index.vue?macro=true";
import { default as indexGNZCdS0gmuMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/user/index.vue?macro=true";
import { default as resultFYE7yIKBOTMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/result.vue?macro=true";
import { default as _91slug_93QPbWhxXDJNMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/similar/[slug].vue?macro=true";
import { default as index5gSNIgIfuUMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/sports/channels/index.vue?macro=true";
import { default as indexkj6VRjcjeGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/sports/index.vue?macro=true";
import { default as SectionAds9NKtD3aFzqMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/subscriptions/components/SectionAds.vue?macro=true";
import { default as SectionContentCLuRd4n5sRMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/subscriptions/components/SectionContent.vue?macro=true";
import { default as SectionDevicesvaRLOBsScsMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/subscriptions/components/SectionDevices.vue?macro=true";
import { default as SectionDownload8it6lo7MgqMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/subscriptions/components/SectionDownload.vue?macro=true";
import { default as SectionHeaderKNM5l72YvKMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/subscriptions/components/SectionHeader.vue?macro=true";
import { default as SectionSubscriptionsyqzCVY69wrMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/subscriptions/components/SectionSubscriptions.vue?macro=true";
import { default as SectionVoiceoverk3LBb8koviMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/subscriptions/components/SectionVoiceover.vue?macro=true";
import { default as indexCAhTkBpyh3Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/subscriptions/index.vue?macro=true";
import { default as termswfrVyNVMyNMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/terms.vue?macro=true";
import { default as upcomingRYCpvULKq9Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/upcoming.vue?macro=true";
export default [
  {
    name: "browse-category",
    path: "/browse/:category()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "browse-components",
    path: "/browse/components",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/index.ts").then(m => m.default || m)
  },
  {
    name: "browse-components-PageFilters",
    path: "/browse/components/PageFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/PageFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionCategories",
    path: "/browse/components/SectionCategories",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/SectionCategories.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionCollectionList",
    path: "/browse/components/SectionCollectionList",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/SectionCollectionList.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionFilters",
    path: "/browse/components/SectionFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/SectionFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionGenres",
    path: "/browse/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionMovies",
    path: "/browse/components/SectionMovies",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/SectionMovies.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionPopular",
    path: "/browse/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionSeries",
    path: "/browse/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonFilters",
    path: "/browse/components/SkeletonFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/SkeletonFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonGenres",
    path: "/browse/components/SkeletonGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/components/SkeletonGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-genre-slug",
    path: "/browse/genre/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: "browse",
    path: "/browse",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/browse/index.vue").then(m => m.default || m)
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/collections/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "copyright",
    path: "/copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/copyright.vue").then(m => m.default || m)
  },
  {
    name: "crew-id",
    path: "/crew/:id()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/crew/[id].vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-all",
    path: "/favorites/all",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/favorites/all.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionBought",
    path: "/favorites/components/SectionBought",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/favorites/components/SectionBought.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionContinue",
    path: "/favorites/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/favorites/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionFavorites",
    path: "/favorites/components/SectionFavorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/favorites/components/SectionFavorites.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionViewed",
    path: "/favorites/components/SectionViewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/favorites/components/SectionViewed.vue").then(m => m.default || m)
  },
  {
    name: "favorites-continue",
    path: "/favorites/continue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/favorites/continue.vue").then(m => m.default || m)
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-purchased",
    path: "/favorites/purchased",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/favorites/purchased.vue").then(m => m.default || m)
  },
  {
    name: "favorites-viewed",
    path: "/favorites/viewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/favorites/viewed.vue").then(m => m.default || m)
  },
  {
    name: "holder-policy",
    path: "/holder-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/holder-policy.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionAdvantages",
    path: "/home/components/SectionAdvantages",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionAdvantages.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionBanner",
    path: "/home/components/SectionBanner",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionBanner.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionCollections",
    path: "/home/components/SectionCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionCollections.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionContinue",
    path: "/home/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionFilms",
    path: "/home/components/SectionFilms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionFilms.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionGenres",
    path: "/home/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionHeader",
    path: "/home/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPopular",
    path: "/home/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPremiers",
    path: "/home/components/SectionPremiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionPremiers.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSeries",
    path: "/home/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSubscriptions",
    path: "/home/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSummarize",
    path: "/home/components/SectionSummarize",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionSummarize.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionTopMovies",
    path: "/home/components/SectionTopMovies",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionTopMovies.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionUpcoming",
    path: "/home/components/SectionUpcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/components/SectionUpcoming.vue").then(m => m.default || m)
  },
  {
    name: "home-Index",
    path: "/home/Index",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/home/Index.vue").then(m => m.default || m)
  },
  {
    name: "install-slug",
    path: "/install/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/install/[slug].vue").then(m => m.default || m)
  },
  {
    name: "install-components-InfoInstall",
    path: "/install/components/InfoInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/install/components/InfoInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-TitleInstall",
    path: "/install/components/TitleInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/install/components/TitleInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-types",
    path: "/install/components/types",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/install/components/types.ts").then(m => m.default || m)
  },
  {
    name: "install-components-useInstall",
    path: "/install/components/useInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/install/components/useInstall.ts").then(m => m.default || m)
  },
  {
    name: "movie-slug-filmcrew",
    path: "/movie/:slug()/filmcrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/[slug]/filmcrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-slug",
    path: "/movie/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCollections",
    path: "/movie/components/MovieCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieCollections.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCrew",
    path: "/movie/components/MovieCrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieCrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieDescription",
    path: "/movie/components/MovieDescription",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieDescription.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeader",
    path: "/movie/components/MovieHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieHeader.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderNotice",
    path: "/movie/components/MovieHeaderNotice",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieHeaderNotice.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderSkeleton",
    path: "/movie/components/MovieHeaderSkeleton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieHeaderSkeleton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MoviePlayButton",
    path: "/movie/components/MoviePlayButton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MoviePlayButton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieRelated",
    path: "/movie/components/MovieRelated",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieRelated.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieSeries",
    path: "/movie/components/MovieSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieSeries.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieTrailers",
    path: "/movie/components/MovieTrailers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieTrailers.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieWatchOptions",
    path: "/movie/components/MovieWatchOptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/movie/components/MovieWatchOptions.vue").then(m => m.default || m)
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "novelty-films",
    path: "/novelty-films",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/novelty-films.vue").then(m => m.default || m)
  },
  {
    name: "novelty-series",
    path: "/novelty-series",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/novelty-series.vue").then(m => m.default || m)
  },
  {
    name: "policy-agreement",
    path: "/policy-agreement",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/policy-agreement.vue").then(m => m.default || m)
  },
  {
    name: "policy-copyright",
    path: "/policy-copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/policy-copyright.vue").then(m => m.default || m)
  },
  {
    name: "policy-holder",
    path: "/policy-holder",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/policy-holder.vue").then(m => m.default || m)
  },
  {
    name: "policy-private",
    path: "/policy-private",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/policy-private.vue").then(m => m.default || m)
  },
  {
    name: "policy-user",
    path: "/policy-user",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/policy-user.vue").then(m => m.default || m)
  },
  {
    name: "premiers",
    path: "/premiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/premiers.vue").then(m => m.default || m)
  },
  {
    name: "private-policy",
    path: "/private-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/private-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile-bonuses",
    path: "/profile/bonuses",
    meta: indexJNUNaPlbq6Meta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/bonuses/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VChangeRecurrentCardModal",
    path: "/profile/components/modal/VChangeRecurrentCardModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VEditProfile",
    path: "/profile/components/modal/VEditProfile",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/modal/VEditProfile.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VRejectSubscribeModal",
    path: "/profile/components/modal/VRejectSubscribeModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/modal/VRejectSubscribeModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VRemoveUserData",
    path: "/profile/components/modal/VRemoveUserData",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/modal/VRemoveUserData.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VResumeSubscriptionSuccessModal",
    path: "/profile/components/modal/VResumeSubscriptionSuccessModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-PersonalReferrer",
    path: "/profile/components/PersonalReferrer",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/PersonalReferrer.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-validation",
    path: "/profile/components/validation",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/validation.ts").then(m => m.default || m)
  },
  {
    name: "profile-components-VBonusHistory",
    path: "/profile/components/VBonusHistory",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/VBonusHistory.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationCard",
    path: "/profile/components/VNotificationCard",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/VNotificationCard.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationsSettings",
    path: "/profile/components/VNotificationsSettings",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/VNotificationsSettings.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VPayments",
    path: "/profile/components/VPayments",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/VPayments.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VProfileTabs",
    path: "/profile/components/VProfileTabs",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/VProfileTabs.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VReferralProgram",
    path: "/profile/components/VReferralProgram",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/VReferralProgram.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VSubscriptions",
    path: "/profile/components/VSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/components/VSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "profile-devices",
    path: "/profile/devices",
    meta: indexJEzRpVG5LuMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/devices/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexCbCktHoHf2Meta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: indexi5c8cm7u4VMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-payments",
    path: "/profile/payments",
    meta: indexuS6U9FA6b3Meta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: indextuF79g0eLlMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-user",
    path: "/profile/user",
    meta: indexGNZCdS0gmuMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/profile/user/index.vue").then(m => m.default || m)
  },
  {
    name: "result",
    path: "/result",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/result.vue").then(m => m.default || m)
  },
  {
    name: "similar-slug",
    path: "/similar/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/similar/[slug].vue").then(m => m.default || m)
  },
  {
    name: "sports-channels",
    path: "/sports/channels",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/sports/channels/index.vue").then(m => m.default || m)
  },
  {
    name: "sports",
    path: "/sports",
    meta: indexkj6VRjcjeGMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionAds",
    path: "/subscriptions/components/SectionAds",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/subscriptions/components/SectionAds.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionContent",
    path: "/subscriptions/components/SectionContent",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/subscriptions/components/SectionContent.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDevices",
    path: "/subscriptions/components/SectionDevices",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/subscriptions/components/SectionDevices.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDownload",
    path: "/subscriptions/components/SectionDownload",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/subscriptions/components/SectionDownload.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionHeader",
    path: "/subscriptions/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/subscriptions/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionSubscriptions",
    path: "/subscriptions/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/subscriptions/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionVoiceover",
    path: "/subscriptions/components/SectionVoiceover",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/subscriptions/components/SectionVoiceover.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "upcoming",
    path: "/upcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-443138/src/pages/upcoming.vue").then(m => m.default || m)
  }
]